import type { DefaultOptionType } from 'rc-select/lib/Select'
import type { TechnicalMeansMeasuring } from '@/enums/TechnicalMeansMeasuring'

export enum ReportTypeEnum {
    Forecast = 'forecast',
    Final = 'final',
}

export enum ReportStatusEnum {
    Closed = '0',
    Open = '1',
}

export enum ProjectSpaceTypeEnum {
    User = 'user',
    Company = 'company',
}

export interface ReportStepItemType {
    id: number
    title: string
    footprint: number
    updated_at?: string

    [key: string]: unknown | object[] | ReportStepItemType
}

export interface TechnicalMeanItemType {
    id: number
    title: string
    footprint: number
    updated_at?: string
    equipments?: TechnicalMeanEquipmentItemType

    [key: string]: unknown | object[] | ReportStepItemType
}

export interface TechnicalMeanEquipmentItemType {
    id: number
    name: string
    title: string
    shooting_number_of_days_equipment_used: number
    machinery_number_of_days_equipment_used: number
    lighting_number_of_days_equipment_used: number
    sound_number_of_days_equipment_used: number
    shooting_equipment_type: TechnicalMeansMeasuring
    shooting_equipment_choice: number
    shooting_equipment_amount: number
    machinery_equipment_type: TechnicalMeansMeasuring
    machinery_equipment_choice: number
    machinery_equipment_amount: number
    lighting_equipment_type: TechnicalMeansMeasuring
    lighting_equipment_choice: number
    lighting_equipment_amount: number
    sound_equipment_type: TechnicalMeansMeasuring
    sound_equipment_choice: number
    sound_equipment_amount: number
    cost_of_purchasing_film_and_media: number
    footprint: number
}

export interface ReportType {
    created_at: string
    deco?: ReportStepItemType
    filmingLocation?: ReportStepItemType
    footprint: number | null
    hmc?: ReportStepItemType
    id: number
    is_readonly: boolean
    postProduction?: ReportStepItemType
    production?: ReportStepItemType
    productionLogistics?: ReportStepItemType
    status: number | string | ReportStatusEnum
    status_text: string
    technicalMean?: TechnicalMeanItemType
    transport?: ReportStepItemType
    type: ReportTypeEnum
    updated_at: string
    [key: string]: unknown | ReportStepItemType
}

export interface EcoprodLabel {
    id: number
    answers?: object
    updated_at?: string
    total_available_points: number
    eco_score: number
    bonus_points: number
    total_obtained_points: number
    penalty_points: number
    has_required_number_of_imperative_questions: boolean
    has_answered_all_questions: boolean
    is_valid: boolean
    number_of_answers: number
    number_of_questions: number
    number_of_imperative_questions: number
    number_of_imperative_criteria: number
    missing_questions: number
    missing_imperative_criteria: number
}

export type ProjectType = {
    can_access_report?: boolean
    can_show_actions_menu: boolean
    can_export_cnc?: boolean
    can_export_pdf?: boolean
    can_export_sweep?: boolean
    collaborator_count: number
    currency: string
    final_report?: ReportType | null
    forecast_report?: ReportType | null
    has_final_report?: boolean
    has_forecast_report?: boolean
    has_label_ecoprod?: boolean
    can_close_report?: boolean
    can_open_report?: boolean
    can_delete_report?: boolean
    id: number
    is_contributor: boolean
    is_exercise: boolean
    is_owner: boolean
    is_reader: boolean
    is_readonly: boolean
    is_root: boolean
    name: string
    report?: ReportType | null
    steps: number[]
    tags: number[]
    type: string
    type_name: string
    data_source?: string | null
    production_days: string
    program_length: string
    ecoprod_label?: EcoprodLabel
    ecoprodLabelLevel?: number
    can_validate_the_label?: boolean
    label_tag?: string
    production_companies?: string
    nb_episodes?: number
}

export enum ProjectStepSlug {
    Deco = 'deco',
    DecoSetCreation = 'setCreation',
    DecoVehicles = 'vehicles',
    DecoVfx = 'vfx',
    DecoOffices = 'decoOffices',
    FilmingLocation = 'filmingLocation',
    FilmingLocationIndoor = 'indoor',
    FilmingLocationOutdoor = 'outdoor',
    Hmc = 'hmc',
    HmcOffices = 'hmcOffices',
    HmcCostumesDressing = 'costumesDressing',
    HmcMakeup = 'makeup',
    HmcHairdressing = 'hairdressing',
    Production = 'production',
    ProductionHardware = 'hardware',
    ProductionInsuranceAndServices = 'insuranceAndServices',
    ProductionOffices = 'offices',
    ProductionLogistics = 'productionLogistics',
    ProductionLogisticsMeals = 'meals',
    ProductionLogisticsStageManagement = 'stageManagement',
    ProductionLogisticsHosting = 'hosting',
    ProductionLogisticsWaste = 'waste',

    // TechnicalMeans
    TechnicalMeans = 'technicalMean',
    ShootingMachineryLightingAndSound = 'equipments',
    SpecialShootingEquipment = 'specialShootingEquipments',
    ProductionAndCarManagement = 'productionAndCarManagements',
    LodgeAndCanteenTrucks = 'lodgeAndCanteenTrucks',
    Generator = 'generators',

    Transport = 'transport',
    TransportAirVehicles = 'airVehicles',
    TransportGroupTravels = 'groupTravels',
    TransportMaritimeVehicles = 'maritimeVehicles',
    TransportRailVehicles = 'railVehicles',
    TransportRoadVehicles = 'roadVehicles',

    PostProduction = 'postProduction',
    PostProductionActivities = 'activities',
    DigitalArchiving = 'digitalArchiving',
}

export enum LabelStepSlug {
    ProductionCommunicationEngagement = 'production-communication-engagement',
    Editorial = 'editorial',
    Offices = 'offices',
    FilmingLocations = 'filming-location',
    SetsConstructionsAndAccessories = 'sets-constructions-and-accessories',
    MakeUpHairDressing = 'make-up-hair-dressing',
    Travel = 'travel',
    Management = 'management',
    TechnicalProductionMeans = 'technical-production-means',
    PostProduction = 'post-production',
}

export type ProjectStepType = {
    key: number
    value: string
    slug: ProjectStepSlug
    children?: ProjectStepType[]
}

export type LabelStepType = {
    key: number
    value: string
    slug: ProjectStepSlug
    children?: LabelStepType[]
}

export interface VehicleOptionType extends DefaultOptionType {
    unit: number
}

export type CityType = {
    id: number
    name: string
}
